import { CanvasEvents } from 'fabric';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { MediaImage } from 'editor/src/store/design/types';

import CustomFabricImage from 'editor/src/fabric/CustomFabricImage';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricImageComponent from 'editor/src/component/EditorArea/fabricComponents/FabricImageComponent';
import FabricRectComponent from 'editor/src/component/EditorArea/fabricComponents/FabricRectComponent';
import { ELEMENT_FRAME_COLOR } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/config';
import {
  FRAME_PROPS,
  GHOST_CONTROL_VISIBILITY,
} from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/elementsConstantProps';
import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';

import updateOnImageGhostChange from './updateOnImageGhostChange';

export interface ImagePos {
  px: number;
  py: number;
  pw: number;
  ph: number;
  pr: number;
}

interface Props {
  imageUrl: string;
  assetId: string;
  element: MediaImage;
  isMobile: boolean;
  imagePos: ImagePos;
  onLoad: () => void;
  onUpdate: (imagePos: ImagePos) => void;
}

function Image({ assetId, imageUrl, element, isMobile, onLoad, onUpdate, imagePos }: Props) {
  const { mm2px, px2mm } = useFabricUtils();
  const fabricCanvas = useFabricCanvas();

  const ghostRef = useRef<CustomFabricImage>(null);

  const frameRect: ObjectRect = useMemo(
    () => ({
      left: 0,
      top: 0,
      width: mm2px(element.width),
      height: mm2px(element.height),
      angle: 0,
    }),
    [element.width, element.height, mm2px],
  );

  const onImageLoaded = useCallback(() => {
    const image = ghostRef.current?.getElement();
    if (image && element.imageId !== assetId) {
      let width;
      let height;
      if (image.width / image.height > frameRect.width / frameRect.height) {
        height = frameRect.height;
        width = (image.width * frameRect.height) / image.height;
      } else {
        width = frameRect.width;
        height = (image.height * frameRect.width) / image.width;
      }

      onUpdate({
        px: px2mm((frameRect.width - width) / 2),
        py: px2mm((frameRect.height - height) / 2),
        pw: px2mm(width),
        ph: px2mm(height),
        pr: 0,
      });
    }
    onLoad();
  }, []);

  const isMouseDown = useRef(false);

  const onGhostMouseDown = useCallback(() => {
    isMouseDown.current = true;
  }, []);

  const onGhostMouseMove = useCallback(
    (e: CanvasEvents['mouse:move']) => {
      if (isMouseDown.current) {
        updateOnImageGhostChange(frameRect, ghostRef, e);
      }
    },
    [frameRect],
  );

  const onGhostMouseUp = useCallback(() => {
    isMouseDown.current = false;
  }, []);

  const getFabricGhostOptionsOnUpdate = useCallback(
    (image: CustomFabricImage) => ({
      scaleX: mm2px(imagePos.pw) / image.getElement().width,
      scaleY: mm2px(imagePos.ph) / image.getElement().height,
    }),
    [imagePos.pw, imagePos.ph, mm2px],
  );

  const cornerSize = isMobile ? 18 : 12;

  const onModified = useCallback(() => {
    if (!ghostRef.current) {
      return;
    }

    onUpdate({
      px: px2mm((ghostRef.current.left || 0) - frameRect.left),
      py: px2mm((ghostRef.current.top || 0) - frameRect.top),
      pw: px2mm(Math.max(ghostRef.current.getScaledWidth(), frameRect.width)),
      ph: px2mm(Math.max(ghostRef.current.getScaledHeight(), frameRect.height)),
      pr: ghostRef.current.angle || 0,
    });
  }, []);

  useEffect(() => {
    if (ghostRef.current) {
      fabricCanvas.setActiveObject(ghostRef.current);
    }
  }, []);

  const vptX = fabricCanvas.viewportTransform?.[4] ?? 0;

  return (
    <>
      <FabricImageComponent
        lockSkewingX
        lockSkewingY
        lockScalingFlip
        borderColor={ELEMENT_FRAME_COLOR}
        cornerColor={ELEMENT_FRAME_COLOR}
        cornerStrokeColor={ELEMENT_FRAME_COLOR}
        transparentCorners={false}
        source={imageUrl}
        crossOrigin={undefined}
        angle={imagePos.pr}
        left={frameRect.left + mm2px(imagePos.px)}
        top={frameRect.top + mm2px(imagePos.py)}
        cornerSize={cornerSize}
        zIndex={0}
        controlVisibility={GHOST_CONTROL_VISIBILITY}
        onMouseDown={onGhostMouseDown}
        onMouseMove={onGhostMouseMove}
        onMouseUp={onGhostMouseUp}
        onModified={onModified}
        onImageLoaded={onImageLoaded}
        uuid={element.uuid}
        hasBorders
        hasControls
        ref={ghostRef}
        strokeWidth={0}
        scaleX={1}
        scaleY={1}
        flipX={element.flipX}
        flipY={element.flipY}
        getFabricOptionsOnUpdate={getFabricGhostOptionsOnUpdate}
      />
      <FabricRectComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...FRAME_PROPS}
        width={frameRect.width}
        height={frameRect.height}
        left={frameRect.left}
        top={frameRect.top}
        zIndex={1}
        uuid={element.uuid}
        evented={false}
        objectCaching={false}
        stroke="#000000"
        strokeWidth={1}
      />
      <FabricRectComponent // top
        width={fabricCanvas.getWidth()}
        height={fabricCanvas.getHeight()}
        left={-vptX}
        top={frameRect.top - fabricCanvas.getHeight()}
        zIndex={2}
        uuid={element.uuid}
        evented={false}
        objectCaching={false}
        strokeWidth={0}
        fill="rgba(255,255,255,0.5)"
      />
      <FabricRectComponent // bottom
        width={fabricCanvas.getWidth()}
        height={fabricCanvas.getHeight()}
        left={-vptX}
        top={frameRect.top + frameRect.height}
        zIndex={2}
        uuid={element.uuid}
        evented={false}
        objectCaching={false}
        strokeWidth={0}
        fill="rgba(255,255,255,0.5)"
      />
      <FabricRectComponent // left
        width={Math.abs(-vptX - frameRect.left)}
        height={frameRect.height}
        left={-vptX}
        top={frameRect.top}
        zIndex={2}
        uuid={element.uuid}
        evented={false}
        objectCaching={false}
        strokeWidth={0}
        fill="rgba(255,255,255,0.5)"
      />
      <FabricRectComponent // right
        width={Math.abs(-vptX - frameRect.left)}
        height={frameRect.height}
        left={frameRect.left + frameRect.width}
        top={frameRect.top}
        zIndex={2}
        uuid={element.uuid}
        evented={false}
        objectCaching={false}
        strokeWidth={0}
        fill="rgba(255,255,255,0.5)"
      />
    </>
  );
}

export default React.memo(Image);
